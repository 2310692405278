import React from "react";
import styled, { css } from "styled-components";
import Countdown from "react-countdown";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

// Assets
import Logo from "./../images/svg/logo-black.svg";
import Instagram from "./../images/svg/instagram.svg";
import Facebook from "./../images/svg/facebook.svg";

// Styles
const LogoIcon = styled((props) => <Logo {...props} />)`
  height: 120px;
  width: 120px;
`;

const CountdownWrapper = styled.div`
  background-color: var(--gin);
  display: flex;
  flex-direction: column;
  height: 100vh;
  justify-content: center;
  width: 100vw;
  z-index: 100;

  .countdown {
    align-items: center;
    display: flex;
    flex-direction: column;
  }

  .element-wrapper {
    min-width: 70px;
    padding: 10px;

    @media (min-width: 992px) {
      min-width: 110px;
      padding: 15px;
    }

    @media (min-width: 1200px) {
      min-width: 150px;
      padding: 20px;
    }

    .label {
      display: flex;
      flex-direction: column;
      font-size: 1rem !important;
      text-transform: capitalize;
      text-align: center;

      @media (min-width: 992px) {
        font-size: 1.25rem !important;
      }

      @media (min-width: 1200px) {
        font-size: 1.5rem !important;
      }
    }

    .time-element {
      color: var(--acapulco);
      display: flex;
      flex-direction: column;
      font-family: "Ubuntu", sans-serif;
      font-size: 2rem !important;
      font-weight: 700 !important;
      text-align: center;

      @media (min-width: 992px) {
        font-size: 3rem !important;
      }

      @media (min-width: 1200px) {
        font-size: 3.5rem !important;
      }
    }
  }
`;

const SocialButton = css`
  height: 30px;
  transition: all 0.125s !important;
  width: 30px;

  @media (min-width: 768px) {
    height: 36px;
    width: 36px;
  }

  &:hover {
    fill: var(--terracotta);
  }
`;

const InstagramIcon = styled((props) => <Instagram {...props} />)`
  ${SocialButton}
`;

const FacebookIcon = styled((props) => <Facebook {...props} />)`
  ${SocialButton}
`;

// Types
interface CountdownProps {
  days: number;
  hours: number;
  minutes: number;
  seconds: number;
  completed: boolean;
}

const Aperture = () => {
  const mydate = new Date("2022-01-15T01:00:00");

  // Renderer callback with condition
  const renderer = ({ days, hours, minutes, seconds }: CountdownProps) => {
    return (
      <div className="d-flex mb-4">
        <div className="element-wrapper me-3 me-xl-4">
          <div className="label">días</div>
          <div className="time-element">{days}</div>
        </div>
        <div className="element-wrapper me-3 me-xl-4">
          <div className="label">horas</div>
          <div className="time-element">{hours}</div>
        </div>
        <div className="element-wrapper me-3 me-xl-4">
          <div className="label">minutos</div>
          <div className="time-element">{minutes}</div>
        </div>
        <div className="element-wrapper">
          <div className="label">segundos</div>
          <div className="time-element">{seconds}</div>
        </div>
      </div>
    );
  };

  return (
    <CountdownWrapper>
      <Container>
        <Row>
          <Col className="countdown">
            <LogoIcon className="mb-4" />
            <h1 className="headline mb-4 text-center">
              Prontamente acá nuestro sitio web
            </h1>
            <p className="mb-1 text-center">
              Inicia la cuenta regresiva para la apertura de Vista Lago.
            </p>
            <p className="mb-4 text-center">
              Reserva con nosotros a partir del{" "}
              {mydate.toLocaleDateString("en-GB")}.
            </p>
            <Countdown date={mydate.getTime()} renderer={renderer} />
            <h2 className="mb-4">Síguenos en:</h2>
            <div className="d-flex">
              <a
                href="https://www.instagram.com/vistalagoarenal"
                className="me-4"
              >
                <InstagramIcon />
              </a>
              <a href="https://www.facebook.com/vistalagoarenal">
                <FacebookIcon />
              </a>
            </div>
          </Col>
        </Row>
      </Container>
    </CountdownWrapper>
  );
};
export default Aperture;
